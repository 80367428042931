// src/PaymentMethods.js
import React, { useState, useEffect } from "react";
import { QrCode, Bitcoin, ArrowLeft, Clipboard } from "lucide-react";
import { toast } from "react-toastify";
import axiosInstance from "./axiosConfig"; // Certifique-se de que o axiosInstance está corretamente configurado

const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = `
      inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors
      focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
      disabled:pointer-events-none disabled:opacity-50
      ${variant === "default" ? "bg-primary text-primary-foreground hover:bg-primary/90" : ""}
      ${variant === "destructive" ? "bg-destructive text-destructive-foreground hover:bg-destructive/90" : ""}
      ${variant === "outline" ? "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground" : ""}
      ${variant === "secondary" ? "bg-secondary text-secondary-foreground hover:bg-secondary/80" : ""}
      ${variant === "ghost" ? "hover:bg-accent hover:text-accent-foreground bg-transparent" : ""}
      ${variant === "link" ? "text-primary underline-offset-4 hover:underline" : ""}
      ${size === "default" ? "h-10 px-4 py-2" : ""}
      ${size === "sm" ? "h-9 rounded-md px-3" : ""}
      ${size === "lg" ? "h-11 rounded-md px-8" : ""}
      ${size === "icon" ? "h-10 w-10" : ""}
      ${className}
    `;
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

const Input = React.forwardRef(
  ({ className, type = "text", ...props }, ref) => {
    const classes = `
      flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm
      placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2
      focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50
      ${className}
    `;
    return <input type={type} className={classes} ref={ref} {...props} />;
  },
);
Input.displayName = "Input";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`
      rounded-lg border bg-card text-card-foreground shadow-sm transition-all
      duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1
      ${className}
    `}
    {...props}
  />
));
Card.displayName = "Card";

const PaymentMethods = ({ setCurrentPage }) => {
  const [balanceStep, setBalanceStep] = useState("chooseMethod");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [amountToAdd, setAmountToAdd] = useState("");
  const [transactionData, setTransactionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csrfToken, setCsrfTokenState] = useState("");

  const MIN_AMOUNT = 10;

  const generateSyntheticData = (method, amount) => {
    if (method === "Bitcoin") {
      return {
        qrCode: "https://via.placeholder.com/150",
        amountInReais: amount,
        amountInBTC: (parseFloat(amount) / 50000).toFixed(8),
      };
    }
  };

  const steps = [
    { id: "chooseMethod", title: "Escolher Método" },
    { id: "enterAmount", title: "Informar Valor" },
    { id: "showTransaction", title: "Detalhes da Transação" },
  ];

  const renderStepper = () => (
    <div className="flex items-center justify-center mb-8">
      {steps.map((step, index) => (
        <div key={step.id} className="flex items-center">
          <div
            className={`
              w-8 h-8 flex items-center justify-center rounded-full
              ${balanceStep === step.id ? "bg-primary text-primary-foreground" : "bg-muted text-muted-foreground"}
            `}
          >
            {index + 1}
          </div>
          {index < steps.length - 1 && (
            <div className="w-8 h-px bg-muted mx-2"></div>
          )}
        </div>
      ))}
    </div>
  );

  const handleConclude = () => {
    setCurrentPage("main");
    setBalanceStep("chooseMethod");
    setSelectedPaymentMethod(null);
    setAmountToAdd("");
    setTransactionData(null);
    toast.success("Saldo adicionado com sucesso!");
  };

  const fetchCsrfToken = async () => {
    try {
      const token = localStorage.getItem("token");

      const response = await axiosInstance.get("/csrf-token", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setCsrfTokenState(data.csrf_token);
      } else {
        toast.error("Erro ao obter o token CSRF.");
      }
    } catch (error) {
      console.error("Erro ao obter o token CSRF:", error);
      toast.error("Erro ao obter o token CSRF.");
    }
  };

  useEffect(() => {
    if (balanceStep === "enterAmount") {
      fetchCsrfToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceStep]);

  const handleGeneratePayment = async () => {
    if (selectedPaymentMethod === "PIX") {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");

        const response = await axiosInstance.post(
          "/pagamentos/pix",
          { amount: parseFloat(amountToAdd) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "X-CSRFToken": csrfToken,
            },
          },
        );

        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          console.log("Dados da Transação PIX:", data); // Log para depuração
          setTransactionData({
            qrCode: data.qrCodeUrl,
            copyAndPasteCode: data.qrCodeText,
            transactionId: data.id,
            status: data.status,
          });
          console.log("Dados após setTransactionData:", {
            qrCode: data.qrCodeUrl,
            copyAndPasteCode: data.qrCodeText,
            transactionId: data.id,
            status: data.status,
          }); // Log para depuração
          setBalanceStep("showTransaction");
          toast.success("Pagamento PIX gerado com sucesso!");
        } else {
          const errorData = response.data;
          toast.error(errorData.error || "Erro ao gerar pagamento PIX.");
        }
      } catch (error) {
        console.error("Erro ao gerar pagamento PIX:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Erro ao gerar pagamento PIX.");
        }
      } finally {
        setIsLoading(false);
      }
    } else if (selectedPaymentMethod === "Bitcoin") {
      const syntheticData = generateSyntheticData(
        selectedPaymentMethod,
        amountToAdd,
      );
      setTransactionData(syntheticData);
      setBalanceStep("showTransaction");
      toast.success("Dados da transação Bitcoin gerados com sucesso!");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full w-full px-4">
      {renderStepper()}

      {balanceStep === "chooseMethod" && (
        <div className="flex flex-col items-center w-full max-w-2xl">
          <div className="flex items-center mb-8 w-full">
            <Button
              variant="ghost"
              onClick={() => {
                setCurrentPage("main");
                setBalanceStep("chooseMethod");
                setSelectedPaymentMethod(null);
                setAmountToAdd("");
                setTransactionData(null);
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Escolha o método de pagamento
            </h2>
          </div>
          <div className="grid grid-cols-2 gap-4 w-full">
            <Card
              onClick={() => {
                setSelectedPaymentMethod("PIX");
                setBalanceStep("enterAmount");
              }}
              className="cursor-pointer hover:shadow-lg transition-shadow w-full"
            >
              <div className="flex flex-col items-center p-3 xs:p-4 sm:p-5">
                <QrCode className="h-12 w-12 xs:h-16 xs:w-16 sm:h-20 sm:w-20 mb-2 xs:mb-3 sm:mb-4" />
                <h3 className="text-base xs:text-lg sm:text-xl font-bold">
                  PIX
                </h3>
              </div>
            </Card>
            <Card
              onClick={() => {
                setSelectedPaymentMethod("Bitcoin");
                setBalanceStep("enterAmount");
              }}
              className="cursor-pointer hover:shadow-lg transition-shadow w-full"
            >
              <div className="flex flex-col items-center p-3 xs:p-4 sm:p-5">
                <Bitcoin className="h-12 w-12 xs:h-16 xs:w-16 sm:h-20 sm:w-20 mb-2 xs:mb-3 sm:mb-4" />
                <h3 className="text-base xs:text-lg sm:text-xl font-bold">
                  Bitcoin
                </h3>
              </div>
            </Card>
          </div>
        </div>
      )}

      {balanceStep === "enterAmount" && (
        <div className="flex flex-col items-center space-y-6">
          <div className="flex items-center">
            <Button
              variant="ghost"
              onClick={() => {
                setBalanceStep("chooseMethod");
                setSelectedPaymentMethod(null);
                setAmountToAdd("");
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Adicionar Saldo via {selectedPaymentMethod}
            </h2>
          </div>
          <Card className="w-full max-w-md p-6">
            <div className="mb-6">
              <label className="block mb-3 text-lg md:text-xl">
                Quanto de saldo em R$ você gostaria de adicionar?
              </label>
              <Input
                type="number"
                value={amountToAdd}
                onChange={(e) => setAmountToAdd(e.target.value)}
                placeholder="Digite a quantia"
                className="text-lg md:text-xl"
                min={MIN_AMOUNT}
              />
              {amountToAdd && parseFloat(amountToAdd) < MIN_AMOUNT && (
                <p className="text-red-500 mt-2">
                  O valor mínimo é R$ {MIN_AMOUNT}
                </p>
              )}
            </div>
            <div className="flex justify-end">
              <Button
                onClick={handleGeneratePayment}
                disabled={
                  !amountToAdd ||
                  parseFloat(amountToAdd) < MIN_AMOUNT ||
                  isLoading ||
                  !csrfToken
                }
              >
                {isLoading
                  ? "Gerando..."
                  : selectedPaymentMethod === "PIX"
                    ? "Gerar PIX"
                    : "Gerar Transação"}
              </Button>
            </div>
          </Card>
        </div>
      )}

      {balanceStep === "showTransaction" && (
        <div className="flex flex-col items-center space-y-6">
          <div className="flex items-center">
            <Button
              variant="ghost"
              onClick={() => {
                setBalanceStep("enterAmount");
                setTransactionData(null);
              }}
              className="flex items-center mr-4"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Voltar
            </Button>
            <h2 className="text-lg xs:text-xl sm:text-2xl md:text-3xl font-bold">
              Detalhes da Transação
            </h2>
          </div>
          <Card className="w-full max-w-md p-6 text-center">
            {selectedPaymentMethod === "PIX" ? (
              <>
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código QR:</strong>
                </p>
                <img
                  src={transactionData.qrCode}
                  alt="QR Code"
                  className="mb-4 mx-auto w-48 h-48 md:w-64 md:h-64"
                />
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código copia e cola:</strong>
                </p>
                <div className="flex items-center justify-center">
                  <Input
                    type="text"
                    value={transactionData.copyAndPasteCode}
                    readOnly
                    className="flex-grow mr-2 text-lg md:text-xl"
                  />
                  <Button
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        transactionData.copyAndPasteCode,
                      );
                      toast.success("Código copiado!");
                    }}
                  >
                    <Clipboard className="h-5 w-5" />
                  </Button>
                </div>
                <div className="mt-4">
                  <Button
                    onClick={async () => {
                      try {
                        const token = localStorage.getItem("token");
                        const response = await axiosInstance.get(
                          `/pagamentos/pix/${transactionData.transactionId}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          },
                        );
                        if (response.status === 200) {
                          const statusData = response.data;
                          toast.info(
                            `Status do pagamento: ${statusData.status}`,
                          );
                          setTransactionData((prevData) => ({
                            ...prevData,
                            status: statusData.status,
                          }));
                        } else {
                          const errorData = response.data;
                          toast.error(
                            errorData.error || "Erro ao verificar status.",
                          );
                        }
                      } catch (error) {
                        console.error(
                          "Erro ao verificar status do pagamento:",
                          error,
                        );
                        toast.error("Erro ao verificar status do pagamento.");
                      }
                    }}
                  >
                    Verificar Status
                  </Button>
                </div>
                {/* Texto Informativo */}
                <p className="mt-6 text-center text-gray-600">
                  Após completar o pagamento, você pode fechar esta aba. Seu
                  saldo será atualizado automaticamente.
                </p>
              </>
            ) : (
              <>
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Código QR:</strong>
                </p>
                <img
                  src={transactionData.qrCode}
                  alt="QR Code"
                  className="mb-4 mx-auto w-48 h-48 md:w-64 md:h-64"
                />
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Quantidade em R$:</strong> R${" "}
                  {transactionData.amountInReais}
                </p>
                <p className="mb-2 text-lg md:text-xl">
                  <strong>Quantidade em BTC:</strong>{" "}
                  {transactionData.amountInBTC} BTC
                </p>
                {/* Texto Informativo */}
                <p className="mt-6 text-center text-gray-600">
                  Após completar a transação, você pode fechar esta aba. Seu
                  saldo será atualizado automaticamente.
                </p>
              </>
            )}
            {/* Removido o botão "Concluir" */}
          </Card>
        </div>
      )}
    </div>
  );
};

export default PaymentMethods;
