// MinhasCompras.js
import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosConfig";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi"; // Ícone de lupa
import { useMediaQuery } from "react-responsive";
import { X } from "lucide-react"; // Importar o ícone X do lucide-react

// Card Components
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "rounded-lg border bg-card text-card-foreground shadow-sm",
      className,
    )}
    {...props}
  />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={clsx(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

// Table Components
const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="w-full overflow-auto">
    <table
      ref={ref}
      className={clsx("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead ref={ref} className={clsx("[&_tr]:border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={clsx("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={clsx(
      "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={clsx(
      "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      className,
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={clsx(
      "p-4 align-middle [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

// Modal Component atualizado para corresponder ao estilo do App.js
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOverlayClick}
    >
      <div className="relative bg-background rounded-lg shadow-lg w-11/12 max-w-lg p-6">
        {/* Botão de fechar */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
        >
          <X className="h-6 w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

const MinhasCompras = () => {
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const response = await axiosInstance.get("/purchase-history");
        setPurchaseHistory(response.data.purchase_history);
        setLoading(false);
      } catch (err) {
        console.error("Erro ao buscar histórico de compras:", err);
        setError("Falha ao carregar o histórico de compras.");
        setLoading(false);
      }
    };

    fetchPurchaseHistory();
  }, []);

  const openModal = (purchase) => {
    setSelectedPurchase(purchase);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPurchase(null);
    setIsModalOpen(false);
  };

  if (loading) return <div>Carregando histórico de compras...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Meu Histórico de Compras</CardTitle>
      </CardHeader>
      <CardContent>
        {isSmallScreen ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Data da Compra</TableHead>
                <TableHead>Ações</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {purchaseHistory.map((purchase, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(purchase.purchase_date).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <button onClick={() => openModal(purchase)}>
                      <FiSearch />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Data da Compra</TableHead>
                <TableHead>Bin</TableHead>
                <TableHead>Número</TableHead>
                <TableHead>Mês</TableHead>
                <TableHead>Ano</TableHead>
                <TableHead>CVV</TableHead>
                <TableHead>Vendor</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Level</TableHead>
                <TableHead>Bank</TableHead>
                <TableHead>Country</TableHead>
                <TableHead>Nome</TableHead>
                <TableHead>CPF</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {purchaseHistory.map((card, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(card.purchase_date).toLocaleString()}
                  </TableCell>
                  <TableCell>{card.bin}</TableCell>
                  <TableCell>{card.number}</TableCell>
                  <TableCell>{card.month}</TableCell>
                  <TableCell>{card.year}</TableCell>
                  <TableCell>{card.cvv}</TableCell>
                  <TableCell>{card.vendor}</TableCell>
                  <TableCell>{card.type}</TableCell>
                  <TableCell>{card.level}</TableCell>
                  <TableCell>{card.bank}</TableCell>
                  <TableCell>{card.country}</TableCell>
                  <TableCell>{card.nome}</TableCell>
                  <TableCell>{card.cpf}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {/* Modal para telas menores */}
        {selectedPurchase && (
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <h2 className="text-xl font-bold mb-4">Detalhes da Compra</h2>
            <div className="space-y-2">
              <p>
                <strong>Data da Compra:</strong>{" "}
                {new Date(selectedPurchase.purchase_date).toLocaleString()}
              </p>
              <p>
                <strong>Bin:</strong> {selectedPurchase.bin}
              </p>
              <p>
                <strong>Número:</strong> {selectedPurchase.number}
              </p>
              <p>
                <strong>Mês:</strong> {selectedPurchase.month}
              </p>
              <p>
                <strong>Ano:</strong> {selectedPurchase.year}
              </p>
              <p>
                <strong>CVV:</strong> {selectedPurchase.cvv}
              </p>
              <p>
                <strong>Vendor:</strong> {selectedPurchase.vendor}
              </p>
              <p>
                <strong>Type:</strong> {selectedPurchase.type}
              </p>
              <p>
                <strong>Level:</strong> {selectedPurchase.level}
              </p>
              <p>
                <strong>Bank:</strong> {selectedPurchase.bank}
              </p>
              <p>
                <strong>Country:</strong> {selectedPurchase.country}
              </p>
              <p>
                <strong>Nome:</strong> {selectedPurchase.nome}
              </p>
              <p>
                <strong>CPF:</strong> {selectedPurchase.cpf}
              </p>
            </div>
            <button
              onClick={closeModal}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
            >
              Fechar
            </button>
          </Modal>
        )}
      </CardContent>
    </Card>
  );
};

export default MinhasCompras;
