// AdvancedSearch.js
import React, { useState } from "react";
import { Search, Filter, Plus, X } from "lucide-react";

const AdvancedSearch = ({ onSearchChange, onFiltersChange, Button, Input }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([{ type: "brand", value: "" }]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    onSearchChange(value);
  };

  const handleFilterTypeChange = (index, newType) => {
    const updatedFilters = [...filters];
    updatedFilters[index].type = newType;
    updatedFilters[index].value = ""; // Resetar o valor quando o tipo muda
    setFilters(updatedFilters);
    onFiltersChange(updatedFilters);
  };

  const handleFilterValueChange = (index, newValue) => {
    const updatedFilters = [...filters];
    updatedFilters[index].value = newValue;
    setFilters(updatedFilters);
    onFiltersChange(updatedFilters);
  };

  const addFilter = () => {
    setFilters([...filters, { type: "brand", value: "" }]);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...filters];
    updatedFilters.splice(index, 1);
    setFilters(updatedFilters);
    onFiltersChange(updatedFilters);
  };

  return (
    <div className="w-full">
      {/* Barra de Busca com Ícone */}
      <div className="flex items-center relative">
        <Input
          type="search"
          placeholder="Pesquisar..."
          className="w-full pl-10 bg-input text-foreground"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
        <Button
          variant="outline"
          size="icon"
          onClick={toggleFilters}
          className="ml-2"
        >
          <Filter className="h-5 w-5" />
        </Button>
      </div>

      {/* Filtros */}
      {showFilters && (
        <div className="mt-2 space-y-2">
          {filters.map((filter, index) => (
            <div key={index} className="flex items-center space-x-2">
              <select
                value={filter.type}
                onChange={(e) => handleFilterTypeChange(index, e.target.value)}
                className="rounded-md border border-input bg-background px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary"
              >
                <option value="brand">Bandeira</option>
                <option value="expiry">Validade</option>
                <option value="bin">BIN</option>
                <option value="name">Nome</option>
                <option value="bank">Banco</option>{" "}
                {/* Adicionado opção "Banco" */}
              </select>
              <Input
                type="text"
                placeholder={
                  filter.type === "brand"
                    ? "Ex: Visa"
                    : filter.type === "expiry"
                      ? "Ex: 12/25"
                      : filter.type === "bin"
                        ? "Ex: 411111"
                        : filter.type === "name"
                          ? "Ex: João Silva"
                          : filter.type === "bank" // Placeholder para "Banco"
                            ? "Ex: NU PAGAMENTOS SA"
                            : "Pesquisar..."
                }
                value={filter.value}
                onChange={(e) => handleFilterValueChange(index, e.target.value)}
                className="flex-grow"
              />
              {/* Botão para remover o filtro */}
              <Button
                variant="outline"
                size="icon"
                onClick={() => removeFilter(index)}
                className="ml-2"
              >
                <X className="h-4 w-4" />
              </Button>
              {/* Botão para adicionar um novo filtro (apenas no último) */}
              {index === filters.length - 1 && (
                <Button
                  variant="outline"
                  size="icon"
                  onClick={addFilter}
                  className="ml-2"
                >
                  <Plus className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdvancedSearch;
