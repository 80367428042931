// src/AstraShopLogin.js
import React, { useState } from "react";
import axiosInstance from "./axiosConfig";
import { User, Lock, LogIn, UserPlus } from "lucide-react";

const Button = ({
  children,
  type = "button",
  onClick,
  className,
  disabled,
  icon: Icon,
}) => {
  const baseStyle =
    "flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring";
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseStyle} ${className}`}
      disabled={disabled}
    >
      {Icon && <Icon className="w-5 h-5" />}
      {children}
    </button>
  );
};

const Input = ({
  id,
  name,
  type = "text",
  className,
  placeholder,
  value,
  onChange,
}) => {
  const baseStyle =
    "w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500";
  return (
    <input
      id={id}
      name={name}
      type={type}
      className={`${baseStyle} ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

const Card = ({ children, className }) => {
  return (
    <div className={`bg-gray-800 rounded-lg shadow-xl ${className}`}>
      {children}
    </div>
  );
};

const CardHeader = ({ children }) => {
  return <div className="p-4 border-b border-gray-700">{children}</div>;
};

const CardContent = ({ children }) => {
  return <div className="p-4">{children}</div>;
};

const CardFooter = ({ children }) => {
  return <div className="p-4 border-t border-gray-700">{children}</div>;
};

export default function AstraShopLogin({ onLoginSuccess }) {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const endpoint = isRegistering ? "register" : "login";
      const response = await axiosInstance.post(`/${endpoint}`, formData);

      if (isRegistering) {
        alert("Registro bem-sucedido! Você pode fazer login agora.");
        setIsRegistering(false);
      } else if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        onLoginSuccess();
        console.log("Login bem-sucedido!");
      }
    } catch (err) {
      setError(
        err.response?.data?.error ||
          `Erro ao ${isRegistering ? "registrar" : "fazer login"}. Tente novamente.`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggle = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsRegistering(!isRegistering);
      setIsTransitioning(false);
    }, 300);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <Card className="w-full max-w-md p-8 space-y-8 bg-gray-800 rounded-lg shadow-xl">
        <CardHeader>
          <div className="text-center">
            <div className="flex justify-center">
              <svg
                className="w-16 h-16 text-blue-500"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2L2 7L12 12L22 7L12 2Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 17L12 22L22 17"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 12L12 17L22 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h2 className="mt-6 text-3xl font-bold text-white">AstraShop</h2>
            <h3 className="mt-2 text-xl text-gray-300">
              {isRegistering ? "Crie sua conta" : "Bem-vindo ao AstraShop"}
            </h3>
          </div>
        </CardHeader>

        <CardContent>
          <div
            className={`transition-opacity duration-300 ${
              isTransitioning ? "opacity-0" : "opacity-100"
            }`}
          >
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-4">
                <div className="relative">
                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <Input
                    id="username"
                    name="username"
                    type="text"
                    required
                    className="pl-10 bg-gray-700 text-white border-gray-600"
                    placeholder="Digite seu usuário"
                    value={formData.username}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="pl-10 bg-gray-700 text-white border-gray-600"
                    placeholder="Digite sua senha"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {error && (
                <p className="text-red-500 text-sm text-center">{error}</p>
              )}

              <Button
                type="submit"
                disabled={isLoading}
                className="w-full"
                icon={isRegistering ? UserPlus : LogIn}
              >
                {isLoading
                  ? isRegistering
                    ? "Registrando..."
                    : "Entrando..."
                  : isRegistering
                    ? "Registrar"
                    : "Entrar"}
              </Button>
            </form>
          </div>
        </CardContent>

        <CardFooter className="flex flex-col items-center">
          <button
            onClick={handleToggle}
            className="flex items-center justify-center w-full text-blue-500 hover:text-blue-600 transition-colors duration-200 mt-4"
            disabled={isTransitioning}
          >
            {isRegistering ? (
              <>
                <LogIn className="w-5 h-5 mr-2" />
                <span>Já é membro? Faça login</span>
              </>
            ) : (
              <>
                <UserPlus className="w-5 h-5 mr-2" />
                <span>Novo por aqui? Crie sua conta</span>
              </>
            )}
          </button>
          <p className="mt-8 text-center text-sm text-gray-400">
            ©2024 AstraShop. Todos os direitos reservados.
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}
