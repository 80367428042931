// Menu.js
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  X,
  Home,
  Settings,
  HelpCircle,
  LogOut,
  Sun,
  Moon,
  ShoppingBag,
} from "lucide-react";
import axiosInstance from "./axiosConfig";

// Button Component
const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = clsx(
      "inline-flex items-center justify-center rounded-md text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      {
        "bg-primary text-primary-foreground hover:bg-primary/90":
          variant === "default",
        "bg-destructive text-destructive-foreground hover:bg-destructive/90":
          variant === "destructive",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
          variant === "outline",
        "bg-secondary text-secondary-foreground hover:bg-secondary/80":
          variant === "secondary",
        "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
        "text-primary underline-offset-4 hover:underline": variant === "link",
      },
      {
        "h-11 px-5 py-2.5": size === "default",
        "h-10 rounded-md px-4": size === "sm",
        "h-12 rounded-md px-8": size === "lg",
        "h-11 w-11": size === "icon",
      },
      className,
    );
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

const Menu = ({
  menuOpen,
  setMenuOpen,
  setCurrentPage,
  onLogout,
  theme,
  toggleTheme,
  username,
}) => {
  const [balance, setBalance] = useState(null);
  const [profileImage, setProfileImage] = useState("");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get("/user-info");
        const { user_info } = response.data;
        setBalance(user_info.balance);
        setProfileImage(user_info.profile_image);
      } catch (error) {
        console.error("Erro ao buscar informações do usuário:", error);
      }
    };

    if (username) {
      fetchUserInfo();
    }
  }, [username]);

  return (
    <div
      className={clsx(
        menuOpen ? "translate-x-0" : "-translate-x-full",
        "fixed inset-y-0 left-0 z-50 bg-secondary shadow-lg transition-all duration-300 ease-in-out lg:relative lg:translate-x-0 flex flex-col",
        "w-60 sm:w-56 md:w-60 lg:w-64", // Larguras padrão para telas menores
        "2xl:w-80", // Largura maior apenas para telas extra grandes (2xl)
        "h-screen", // Mantém a altura fixa
      )}
    >
      {/* Cabeçalho do Menu */}
      <div className="flex items-center justify-between p-6 border-b border-border">
        <h2 className="text-2xl font-bold">Menu</h2>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setMenuOpen(false)}
          className="lg:hidden"
        >
          <X className="h-6 w-6" />
        </Button>
      </div>

      {/* Área de navegação com scroll */}
      <nav className="flex-1 overflow-y-auto py-4 px-4 sm:px-6">
        <ul className="space-y-1 sm:space-y-2">
          <li>
            <button
              onClick={() => {
                setCurrentPage("inicio");
                setMenuOpen(false);
              }}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              <Home className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
              Início
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setCurrentPage("minhas-compras");
                setMenuOpen(false);
                // Remova o setMenuOpen(false) aqui para que o menu não suma
              }}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              <ShoppingBag className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
              Minhas Compras
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setCurrentPage("settings");
                setMenuOpen(false);
              }}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              <Settings className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
              Configurações
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setCurrentPage("ajuda");
                setMenuOpen(false);
              }}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              <HelpCircle className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
              Ajuda
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                onLogout();
                setMenuOpen(false);
              }}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              <LogOut className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
              Sair
            </button>
          </li>
          <li className="mt-6">
            <button
              onClick={toggleTheme}
              className="flex items-center px-4 py-2 sm:px-6 sm:py-2.5 text-sm sm:text-base hover:bg-accent hover:text-accent-foreground w-full text-left"
            >
              {theme === "light" ? (
                <>
                  <Moon className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
                  Modo Escuro
                </>
              ) : (
                <>
                  <Sun className="mr-4 h-5 w-5 sm:h-6 sm:w-6" />
                  Modo Claro
                </>
              )}
            </button>
          </li>
        </ul>
      </nav>

      {/* Parte Inferior do Menu: Nome do Usuário, Saldo e Imagem de Perfil */}
      <div className="p-6 border-t border-border flex items-center">
        <img
          src={profileImage || "/default-profile-image.jpg"}
          alt="Imagem de Perfil"
          className="w-12 h-12 rounded-full mr-4 object-cover"
        />
        <div>
          <p className="text-lg font-semibold">{username}</p>
          {balance !== null && (
            <p className="text-sm text-gray-400">
              Saldo: R$ {balance.toFixed(2)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
