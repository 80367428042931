// src/FinalizarCompra.js
import React, { useState, useCallback } from "react";
import { Trash2, Check } from "lucide-react";
import clsx from "clsx";
import axiosInstance from "./axiosConfig"; // Import axiosInstance
import { toast } from "react-toastify"; // Import only 'toast'

// Componente Button
const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = clsx(
      "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      {
        "bg-primary text-primary-foreground hover:bg-primary/90":
          variant === "default",
        "bg-destructive text-destructive-foreground hover:bg-destructive/90":
          variant === "destructive",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
          variant === "outline",
        "bg-secondary text-secondary-foreground hover:bg-secondary/80":
          variant === "secondary",
        "hover:bg-accent hover:text-accent-foreground": variant === "ghost",
        "text-primary underline-offset-4 hover:underline": variant === "link",
      },
      {
        "h-10 px-4 py-2": size === "default",
        "h-9 rounded-md px-3": size === "sm",
        "h-11 rounded-md px-8": size === "lg",
        "h-10 w-10": size === "icon",
      },
      className,
    );
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

// Componente Checkbox personalizado
const Checkbox = ({ checked, onChange }) => {
  return (
    <div
      onClick={onChange}
      className={clsx(
        "w-5 h-5 border-2 rounded cursor-pointer flex items-center justify-center transition-colors duration-200",
        checked ? "bg-primary border-primary" : "bg-background border-input",
      )}
    >
      {checked && <Check className="w-4 h-4 text-primary-foreground" />}
    </div>
  );
};

const FinalizarCompra = ({
  cart,
  setCart,
  onClose,
  username,
  userBalance,
  setUserBalance,
  updateCartOnServer,
}) => {
  const [selectedItems, setSelectedItems] = useState({});

  const handleSelectItem = useCallback((index) => {
    setSelectedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  // Calcular o total do carrinho (todos os itens)
  const totalCartPrice = cart.reduce((total, item) => {
    const price = parseFloat(item.price);
    return total + (isNaN(price) ? 0 : price);
  }, 0);

  // Calcular o total da compra (itens selecionados)
  const totalPrice = Object.keys(selectedItems).reduce((total, key) => {
    if (selectedItems[key]) {
      const price = parseFloat(cart[key].price);
      return total + (isNaN(price) ? 0 : price);
    }
    return total;
  }, 0);

  const finalBalance = userBalance - totalPrice;

  const handleFinalize = async () => {
    const selectedIndexes = Object.keys(selectedItems).filter(
      (key) => selectedItems[key],
    );
    if (selectedIndexes.length === 0) {
      toast.warn(
        "Por favor, selecione pelo menos um item para finalizar a compra.",
      );
      return;
    }

    if (totalPrice > userBalance) {
      toast.error("Saldo insuficiente para realizar a compra.");
      return;
    }

    const finalizedItems = selectedIndexes.map((index) => cart[index]);

    try {
      // Fazer a solicitação de compra usando axiosInstance
      const response = await axiosInstance.post("/purchase", {
        cards: finalizedItems,
      });

      if (response.status === 200) {
        const { purchased_cards, new_balance, failed_cards } = response.data;

        // Atualizar o saldo do usuário no estado global
        setUserBalance(new_balance);

        // Remover os itens comprados do carrinho localmente
        const remainingCart = cart.filter((_, index) => !selectedItems[index]);
        setCart(remainingCart);
        setSelectedItems({});

        // Sincronizar o carrinho com o backend
        updateCartOnServer(remainingCart);

        toast.success("Compra finalizada com sucesso!");

        // Adicione um atraso antes de fechar o modal
        setTimeout(() => {
          onClose();
        }, 500); // Atraso de 500ms
      } else {
        // Lidar com a resposta de erro
        const { error } = response.data;
        toast.error(
          `Erro ao finalizar compra: ${error || "Erro desconhecido"}`,
        );
      }
    } catch (error) {
      console.error("Erro ao finalizar compra:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Erro ao finalizar compra: ${error.response.data.error}`);
      } else {
        toast.error("Ocorreu um erro ao finalizar a compra.");
      }
    }
  };

  const handleRemoveItem = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
    setSelectedItems((prev) => {
      const newSelected = { ...prev };
      delete newSelected[index];
      return newSelected;
    });
    // Atualize o carrinho no servidor
    updateCartOnServer(newCart);
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-4">Finalizar Compra</h2>

      <p className="mb-2">
        Usuário: <span className="font-semibold">{username}</span>
      </p>
      <p className="mb-2">
        Saldo atual:{" "}
        <span className="font-semibold">R$ {userBalance.toFixed(2)}</span>
      </p>

      {cart.length === 0 ? (
        <p className="text-center">Seu carrinho está vazio.</p>
      ) : (
        <div className="space-y-4">
          {cart.map((item, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row items-start sm:items-center justify-between p-4 border border-border rounded-md"
            >
              <div className="flex items-center space-x-4">
                <Checkbox
                  checked={!!selectedItems[index]}
                  onChange={() => handleSelectItem(index)}
                />
                <div>
                  <p className="font-semibold">{item.nome}</p>
                  <p className="text-sm text-muted-foreground">
                    BIN: {item.bin} | Validade: {item.month}/{item.year}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    Preço: R${" "}
                    {item.price !== undefined && !isNaN(item.price)
                      ? parseFloat(item.price).toFixed(2)
                      : "0.00"}
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleRemoveItem(index)}
                className="mt-4 sm:mt-0 sm:ml-4 text-destructive hover:text-destructive-foreground"
                aria-label="Remover item"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Exibir o total dos itens selecionados e saldo após compra */}
      <div className="mt-4">
        <p className="text-lg font-semibold">
          Total dos itens selecionados: R$ {totalPrice.toFixed(2)}
        </p>
      </div>

      <div className="mt-6 flex justify-end space-x-4">
        <Button
          variant="default"
          onClick={handleFinalize}
          disabled={Object.values(selectedItems).filter(Boolean).length === 0}
        >
          Finalizar Compra
        </Button>
      </div>
    </div>
  );
};

export default FinalizarCompra;
