import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import clsx from "clsx";
import { jwtDecode } from "jwt-decode";
import axiosInstance, { setAuthToken, setCsrfToken } from "./axiosConfig";
import DOMPurify from "dompurify";
import MinhasCompras from "./MinhasCompras";
import {
  Menu as MenuIcon,
  X,
  ShoppingCart,
  CreditCard,
  User,
  Lock,
  Calendar,
  Trash2,
  Landmark,
  CheckCircle,
  Share2,
  ArrowLeft,
  DollarSign,
} from "lucide-react";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcAmex,
  FaCcDiscover,
} from "react-icons/fa";
import AstraShopLogin from "./AstraShopLogin";
import Menu from "./Menu";
import FinalizarCompra from "./FinalizarCompra";
import AdvancedSearch from "./AdvancedSearch";
import UserSettings from "./UserSettings";
import PaymentMethods from "./PaymentMethods";

// Import react-toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Button Component
const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const classes = clsx(
      "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      {
        "bg-primary text-primary-foreground hover:bg-primary/90":
          variant === "default",
        "bg-destructive text-destructive-foreground hover:bg-destructive/90":
          variant === "destructive",
        "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground":
          variant === "outline",
        "bg-secondary text-secondary-foreground hover:bg-secondary/80":
          variant === "secondary",
        "hover:bg-accent hover:text-accent-foreground bg-transparent":
          variant === "ghost",
        "text-primary underline-offset-4 hover:underline": variant === "link",
      },
      {
        "h-10 px-4 py-2": size === "default",
        "h-9 rounded-md px-3": size === "sm",
        "h-11 rounded-md px-8": size === "lg",
        "h-10 w-10": size === "icon",
      },
      className,
    );
    return <button className={classes} ref={ref} {...props} />;
  },
);
Button.displayName = "Button";

// Input Component
const Input = React.forwardRef(
  ({ className, type = "text", ...props }, ref) => {
    const classes = clsx(
      "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className,
    );
    return <input type={type} className={classes} ref={ref} {...props} />;
  },
);
Input.displayName = "Input";

// Card Components
const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "rounded-lg border bg-card text-card-foreground shadow-sm transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1",
      className,
    )}
    {...props}
  />
));
Card.displayName = "Card";

// Updated padding in CardHeader and CardContent for responsive design
const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("flex flex-col space-y-1.5 p-4 md:p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={clsx(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("p-4 pt-0 md:p-6 md:pt-0", className)}
    {...props}
  />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx("flex items-center p-4 pt-0 md:p-6 md:pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
      onClick={handleOverlayClick}
    >
      <div className="relative bg-background rounded-lg shadow-lg w-full max-w-lg p-6 overflow-auto max-h-full">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
        >
          <X className="h-6 w-6" />
        </button>
        {children}
      </div>
    </div>
  );
};

// Popover Components
const Popover = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return React.Children.map(children, (child) =>
    React.cloneElement(child, { isOpen, setIsOpen }),
  );
};

const PopoverTrigger = ({ children, isOpen, setIsOpen }) => {
  return React.cloneElement(children, {
    onClick: () => setIsOpen(!isOpen),
  });
};

// PopoverContent Component
// PopoverContent Component
const PopoverContent = ({ children, className, isOpen, ...props }) => {
  if (!isOpen) return null;

  return (
    <div
      className={clsx(
        "absolute z-50 mt-2",
        "left-0 transform-none",
        "lg:left-auto lg:right-0",
        // Definir largura responsiva:
        // w-64: 16rem (256px) para telas pequenas
        // sm:w-80: 20rem (320px) para telas pequenas/médias (≥640px)
        // md:w-96: 24rem (384px) para telas médias e maiores (≥768px)
        "w-64 sm:w-80 md:w-96",
        "p-4 bg-popover text-popover-foreground border rounded-md shadow-md outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

// Function to get brand icon
const getBrandIcon = (brand) => {
  switch (brand.toLowerCase()) {
    case "visa":
      return <FaCcVisa className="h-6 w-6" />;
    case "mastercard":
      return <FaCcMastercard className="h-6 w-6" />;
    case "american express":
    case "amex":
      return <FaCcAmex className="h-6 w-6" />;
    case "discover":
      return <FaCcDiscover className="h-6 w-6" />;
    default:
      return <CreditCard className="h-6 w-6" />;
  }
};

// Function to get unique card identifier
const getCardId = (card) => card._id || card.id;

// Updated CardInterface Component
const CardInterface = ({
  currentPage,
  setCurrentPage,
  onLogout,
  username,
  Button,
  Input,
  purchaseMode,
  updatePurchaseMode,
  userBalance,
  setUserBalance,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([]);

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Quick Buy states
  const [isQuickBuyModalOpen, setIsQuickBuyModalOpen] = useState(false);
  const [cardToBuy, setCardToBuy] = useState(null);

  // Fetch cart from server when component mounts
  useEffect(() => {
    if (purchaseMode === "cart") {
      fetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseMode]);

  const fetchCart = async () => {
    try {
      const response = await axiosInstance.get("/cart");
      setCart(response.data.cart);
    } catch (error) {
      console.error("Erro ao buscar o carrinho:", error);
    }
  };

  const fetchCards = async (pageNumber = 1) => {
    setLoading(true);
    setError(null);
    try {
      const params = new URLSearchParams();
      params.append("page", pageNumber);
      params.append("limit", 100);

      if (searchQuery) {
        params.append("search", searchQuery);
      }
      if (filters.length > 0) {
        const validFilters = filters.filter((filter) => filter.value);
        if (validFilters.length > 0) {
          params.append("filters", JSON.stringify(validFilters));
        }
      }

      const response = await axiosInstance.get(
        `/get-cards?${params.toString()}`,
      );
      const fetchedCards = response.data.cards;
      const pagination = response.data.pagination;

      setCards((prevCards) =>
        pageNumber === 1 ? fetchedCards : [...prevCards, ...fetchedCards],
      );

      setPage(pagination.current_page);
      setTotalPages(pagination.total_pages);
    } catch (err) {
      console.error("Erro ao buscar cartões:", err);
      setError("Falha ao carregar os cartões.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch cards when search or filters change
  useEffect(() => {
    setPage(1);
    fetchCards(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filters]);

  useEffect(() => {
    fetchCards(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    if (page < totalPages && !loading) {
      fetchCards(page + 1);
    }
  };

  const addToCart = (card) => {
    const cardId = getCardId(card);
    const isItemInCart = cart.some((item) => getCardId(item) === cardId);
    if (isItemInCart) {
      toast.error(`O item com BIN ${card.bin} já está no carrinho.`);
    } else {
      const newCart = [...cart, card];
      setCart(newCart);
      updateCartOnServer(newCart);
      toast.success(`Item com BIN ${card.bin} adicionado ao carrinho!`);
    }
  };

  const removeFromCart = (index) => {
    const newCart = [...cart];
    newCart.splice(index, 1);
    setCart(newCart);
    updateCartOnServer(newCart);
  };

  const updateCartOnServer = async (newCart) => {
    try {
      await axiosInstance.post("/cart", { cart: newCart });
    } catch (error) {
      console.error("Erro ao atualizar o carrinho:", error);
    }
  };

  const openModal = (card) => {
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedCard(null);
    setIsModalOpen(false);
  };

  const handleQuickBuy = (card) => {
    setCardToBuy(card);
    setIsQuickBuyModalOpen(true);
  };

  const confirmQuickBuy = async () => {
    try {
      const response = await axiosInstance.post("/purchase", {
        cards: [cardToBuy],
      });
      toast.success("Compra realizada com sucesso!");
    } catch (error) {
      console.error("Erro ao processar a compra:", error);
      toast.error("Ocorreu um erro ao processar sua compra.");
    } finally {
      setIsQuickBuyModalOpen(false);
      setCardToBuy(null);
    }
  };

  const shareCardLink = (card) => {
    const cardId = getCardId(card);
    const link = `${window.location.origin}/?card=${cardId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copiado para a área de transferência!");
      })
      .catch((err) => {
        console.error("Falha ao copiar o link: ", err);
      });
  };

  const handleAddBalance = () => {
    setCurrentPage("add-balance");
  };

  // Formatação do saldo
  const formattedBalance = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(userBalance || 0);

  return (
    <div className="flex flex-col h-screen bg-background text-foreground">
      {/* Header for small screens */}
      <div className="flex items-center justify-between p-4 lg:hidden">
        <Button variant="outline" size="icon" onClick={() => setMenuOpen(true)}>
          <MenuIcon className="h-6 w-6" />
        </Button>
      </div>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar - Using Menu component */}
        <Menu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          setCurrentPage={setCurrentPage}
          onLogout={onLogout}
          username={username}
        />

        {/* Main content */}
        <div className="flex-1 overflow-y-auto p-4">
          {currentPage === "settings" ? (
            <div className="flex flex-col items-center">
              <UserSettings
                onClose={() => setCurrentPage("main")}
                updatePurchaseMode={updatePurchaseMode}
                currentMode={purchaseMode}
              />
            </div>
          ) : currentPage === "minhas-compras" ? (
            <div className="container mx-auto px-2 sm:px-4">
              <MinhasCompras />
            </div>
          ) : currentPage === "finalizar-compra" ? (
            <div className="container mx-auto px-2 sm:px-4">
              {/* Back button */}
              <div className="mb-4">
                <Button
                  variant="ghost"
                  onClick={() => setCurrentPage("main")}
                  className="flex items-center"
                >
                  <ArrowLeft className="h-5 w-5 mr-2" />
                  Voltar
                </Button>
              </div>
              <FinalizarCompra
                cart={cart}
                setCart={setCart}
                onClose={() => setCurrentPage("main")}
                username={username}
                userBalance={userBalance}
                setUserBalance={setUserBalance}
                updateCartOnServer={updateCartOnServer}
              />
            </div>
          ) : currentPage === "add-balance" ? (
            <PaymentMethods setCurrentPage={setCurrentPage} />
          ) : (
            // Main content goes here
            <div className="container mx-auto px-2 sm:px-4">
              {/* User greeting */}
              {username && (
                <div className="mb-4">
                  <p className="text-lg font-semibold">
                    Bem-vindo, {username}!
                  </p>
                </div>
              )}

              {/* Search box and icons */}
              <div className="mb-6 flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
                <div className="w-full lg:w-2/3">
                  <AdvancedSearch
                    onSearchChange={setSearchQuery}
                    onFiltersChange={setFilters}
                    Button={Button}
                    Input={Input}
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <div className="flex items-center bg-secondary rounded-md overflow-hidden">
                    <span className="hidden sm:inline-block px-4 py-2 font-semibold text-secondary-foreground">
                      Saldo: {formattedBalance}
                    </span>
                    <Button
                      onClick={handleAddBalance}
                      className="p-2 bg-accent hover:bg-accent-foreground text-accent-foreground hover:text-accent transition-colors rounded-md sm:rounded-l-none"
                      title={`Saldo: ${formattedBalance}`}
                    >
                      <DollarSign className="h-5 w-5" />
                    </Button>
                  </div>

                  {purchaseMode === "cart" && (
                    <div className="relative">
                      <Popover>
                        <PopoverTrigger>
                          <Button
                            variant="outline"
                            size="icon"
                            className="relative bg-secondary text-secondary-foreground hover:bg-accent hover:text-accent-foreground"
                          >
                            <ShoppingCart className="h-5 w-5" />
                            {cart.length > 0 && (
                              <span className="absolute -top-2 -right-2 bg-destructive text-destructive-foreground text-xs rounded-full h-5 w-5 flex items-center justify-center">
                                {cart.length}
                              </span>
                            )}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="bg-popover border-border text-popover-foreground">
                          <h3 className="font-bold mb-2">Itens no Carrinho</h3>
                          {cart.length === 0 ? (
                            <p>Carrinho vazio</p>
                          ) : (
                            <>
                              <ul className="space-y-2 max-h-64 overflow-y-auto">
                                {cart.map((item, index) => (
                                  <li
                                    key={index}
                                    className="border-b border-border pb-2 flex justify-between items-center"
                                  >
                                    <div className="text-sm">
                                      <div className="font-bold">
                                        BIN: {item.bin}
                                      </div>
                                      <div>
                                        Validade: {item.month}/{item.year}
                                      </div>
                                      <div>Nome: {item.nome}</div>
                                      <div>Nível: {item.level}</div>
                                      <div>Banco: {item.bank}</div>
                                    </div>
                                    <button
                                      onClick={() => removeFromCart(index)}
                                      className="text-destructive hover:text-destructive-foreground ml-2"
                                    >
                                      <Trash2 className="h-5 w-5" />
                                    </button>
                                  </li>
                                ))}
                              </ul>
                              <Button
                                variant="default"
                                onClick={() =>
                                  setCurrentPage("finalizar-compra")
                                }
                                className="mt-4 w-full bg-primary text-primary-foreground hover:bg-primary/90"
                              >
                                Finalizar Compra
                              </Button>
                            </>
                          )}
                        </PopoverContent>
                      </Popover>
                    </div>
                  )}
                </div>
              </div>

              {/* Loading or error feedback */}
              {loading && <p className="text-center">Carregando cartões...</p>}
              {error && <p className="text-center text-red-500">{error}</p>}

              {/* Card list */}
              <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                {cards.length > 0
                  ? cards.map((card, index) => (
                      <Card
                        key={index}
                        className="bg-card text-card-foreground cursor-pointer w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                        onClick={() => openModal(card)}
                      >
                        {/* Brand icon as background */}
                        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                          <div className="text-[30rem] transform scale-150 text-gray-300 opacity-10 pointer-events-none">
                            {getBrandIcon(card.vendor)}
                          </div>
                        </div>

                        {/* Card content */}
                        <div className="relative z-10 p-4 sm:p-6">
                          <CardHeader className="p-0">
                            <CardTitle className="flex items-center justify-between text-base sm:text-lg">
                              <span className="flex items-center">
                                {getBrandIcon(card.vendor)}
                                <span className="ml-2 font-semibold">
                                  BIN: {card.bin}
                                </span>
                              </span>
                              <span className="text-sm md:text-base font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full">
                                {card.level}
                              </span>
                            </CardTitle>
                          </CardHeader>
                          <CardContent className="mt-4 p-0">
                            <p className="flex items-center mb-2 text-sm">
                              <User className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">
                                Nome: {card.nome}
                              </span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <Lock className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">CPF: {card.cpf}</span>
                            </p>
                            <p className="flex items-center mb-2 text-sm">
                              <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span>
                                Validade: {card.month}/{card.year}
                              </span>
                            </p>
                            <p className="flex items-center mb-4 text-sm">
                              <Landmark className="h-5 w-5 mr-2 flex-shrink-0" />
                              <span className="truncate">
                                Banco: {card.bank}
                              </span>
                            </p>
                          </CardContent>
                          <CardFooter className="flex justify-between items-center p-0">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                shareCardLink(card);
                              }}
                              className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                            >
                              <Share2 className="h-5 w-5" />
                              <span className="sr-only">Compartilhar Link</span>
                            </Button>
                            {purchaseMode === "cart" ? (
                              <Button
                                size="icon"
                                className="h-10 w-10 bg-customGreen text-customGreen-foreground rounded-full flex items-center justify-center transition-transform duration-200 hover:bg-customGreen-hover hover:scale-110"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  addToCart(card);
                                }}
                              >
                                <ShoppingCart className="h-5 w-5 animate-pulse" />
                                <span className="sr-only">
                                  Adicionar ao carrinho
                                </span>
                              </Button>
                            ) : (
                              <Button
                                size="icon"
                                variant="ghost"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleQuickBuy(card);
                                }}
                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                              >
                                <CheckCircle className="h-5 w-5" />
                                <span className="sr-only">Comprar</span>
                              </Button>
                            )}
                          </CardFooter>
                        </div>
                      </Card>
                    ))
                  : !loading &&
                    !error && (
                      <p className="col-span-full text-center">
                        Nenhum cartão encontrado.
                      </p>
                    )}
              </div>

              {/* Load More Button */}
              {page < totalPages && !loading && (
                <div className="flex justify-center mt-6">
                  <Button onClick={handleLoadMore}>Carregar Mais</Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Card details modal */}
      {selectedCard && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h2 className="text-2xl font-bold mb-4">Detalhes do Cartão</h2>
          <p className="mb-2">
            <strong>BIN:</strong> {DOMPurify.sanitize(selectedCard.bin)}
          </p>
          <p className="mb-2">
            <strong>Validade:</strong> {DOMPurify.sanitize(selectedCard.month)}/
            {DOMPurify.sanitize(selectedCard.year)}
          </p>
          <p className="mb-2">
            <strong>Vendor:</strong> {DOMPurify.sanitize(selectedCard.vendor)}
          </p>
          <p className="mb-2">
            <strong>Tipo:</strong> {DOMPurify.sanitize(selectedCard.type)}
          </p>
          <p className="mb-2">
            <strong>Nível:</strong> {DOMPurify.sanitize(selectedCard.level)}
          </p>
          <p className="mb-2">
            <strong>Banco:</strong> {DOMPurify.sanitize(selectedCard.bank)}
          </p>
          <p className="mb-2">
            <strong>País:</strong> {DOMPurify.sanitize(selectedCard.country)}
          </p>
          <p className="mb-2">
            <strong>Nome:</strong> {DOMPurify.sanitize(selectedCard.nome)}
          </p>
          <p className="mb-2">
            <strong>CPF:</strong> {DOMPurify.sanitize(selectedCard.cpf)}
          </p>
        </Modal>
      )}

      {/* Quick Buy Modal */}
      {isQuickBuyModalOpen && cardToBuy && (
        <Modal
          isOpen={isQuickBuyModalOpen}
          onClose={() => setIsQuickBuyModalOpen(false)}
        >
          <h2 className="text-2xl font-bold mb-4">Confirmar Compra</h2>
          <p className="mb-2">
            Você tem certeza que deseja comprar o seguinte cartão?
          </p>
          <p className="mb-2">
            <strong>BIN:</strong> {DOMPurify.sanitize(cardToBuy.bin)}
          </p>
          <p className="mb-2">
            <strong>Validade:</strong> {DOMPurify.sanitize(cardToBuy.month)}/
            {DOMPurify.sanitize(cardToBuy.year)}
          </p>
          <p className="mb-2">
            <strong>Vendor:</strong> {DOMPurify.sanitize(cardToBuy.vendor)}
          </p>
          {/* Add other details as needed */}
          <div className="flex justify-end mt-4">
            <Button
              variant="outline"
              onClick={() => setIsQuickBuyModalOpen(false)}
              className="mr-2"
            >
              Cancelar
            </Button>
            <Button variant="default" onClick={confirmQuickBuy}>
              Confirmar Compra
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

// Novo componente para exibir o cartão compartilhado em um modal
const SharedCardModal = ({ cardId, onClose }) => {
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await axiosInstance.get(`/card/${cardId}`);
        setCard(response.data);
      } catch (err) {
        console.error("Erro ao obter detalhes do cartão:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCardDetails();
  }, [cardId]);

  if (loading) return null;
  if (error) return null;
  if (!card) return null;

  return (
    <Modal isOpen={true} onClose={onClose}>
      <h2 className="text-2xl font-bold mb-4">
        Detalhes do Cartão Compartilhado
      </h2>
      <div className="mb-2">
        <strong>BIN:</strong> {DOMPurify.sanitize(card.bin)}
      </div>
      <div className="mb-2">
        <strong>Validade:</strong> {DOMPurify.sanitize(card.month)}/
        {DOMPurify.sanitize(card.year)}
      </div>
      <div className="mb-2">
        <strong>Vendor:</strong> {DOMPurify.sanitize(card.vendor)}
      </div>
      <div className="mb-2">
        <strong>Tipo:</strong> {DOMPurify.sanitize(card.type)}
      </div>
      <div className="mb-2">
        <strong>Nível:</strong> {DOMPurify.sanitize(card.level)}
      </div>
      <div className="mb-2">
        <strong>Banco:</strong> {DOMPurify.sanitize(card.bank)}
      </div>
      <div className="mb-2">
        <strong>País:</strong> {DOMPurify.sanitize(card.country)}
      </div>
      <div className="mb-2">
        <strong>Nome:</strong> {DOMPurify.sanitize(card.nome)}
      </div>
      <div className="mb-2">
        <strong>CPF:</strong> {DOMPurify.sanitize(card.cpf)}
      </div>
      {/* Botão para fechar o modal */}
      <Button variant="default" onClick={onClose} className="mt-4">
        Fechar
      </Button>
    </Modal>
  );
};

// Componente para lidar com a lógica principal e detecção do parâmetro de consulta
const MainContent = ({
  currentPage,
  setCurrentPage,
  onLogout,
  username,
  purchaseMode,
  updatePurchaseMode,
  userBalance,
  setUserBalance,
}) => {
  const location = useLocation();
  const [sharedCardId, setSharedCardId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Estado para armazenar o token CSRF
  const [csrfToken, setCsrfTokenState] = useState("");

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axiosInstance.get("/csrf-token");
        const { csrf_token } = response.data;
        setCsrfTokenState(csrf_token);
        setCsrfToken(csrf_token); // Define o token nos headers do axios
      } catch (error) {
        console.error("Erro ao obter o token CSRF:", error);
      }
    };

    // Obter o token CSRF apenas se o usuário estiver autenticado
    if (username) {
      fetchCsrfToken();
    }
  }, [username]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cardId = params.get("card");
    if (cardId) {
      setSharedCardId(cardId);
      setIsModalOpen(true);
    } else {
      setSharedCardId(null);
      setIsModalOpen(false);
    }
  }, [location.search]);

  return (
    <>
      <CardInterface
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onLogout={onLogout}
        username={username}
        Button={Button}
        Input={Input}
        purchaseMode={purchaseMode}
        updatePurchaseMode={updatePurchaseMode}
        userBalance={userBalance}
        setUserBalance={setUserBalance}
      />
      {sharedCardId && isModalOpen && (
        <SharedCardModal
          cardId={sharedCardId}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState("main");
  const [username, setUsername] = useState(null);
  const [purchaseMode, setPurchaseMode] = useState("cart");
  const [userBalance, setUserBalance] = useState(0);

  useEffect(() => {
    checkAuthentication();
    // Load user preference from localStorage
    const savedMode = localStorage.getItem("purchaseMode") || "cart";
    setPurchaseMode(savedMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const fetchUserBalance = async () => {
    try {
      const response = await axiosInstance.get("/user-info");
      setUserBalance(response.data.user_info.balance);
    } catch (error) {
      console.error("Erro ao obter saldo do usuário:", error);
    }
  };

  const updatePurchaseMode = (mode) => {
    setPurchaseMode(mode);
    localStorage.setItem("purchaseMode", mode);
  };

  const checkAuthentication = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsAuthenticated(true);
        setUsername(decoded.user);
        setAuthToken(token); // Define o token nos headers do axios
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        setIsAuthenticated(false);
        setUsername(null);
        setAuthToken(null); // Remove o token dos headers do axios
      }
    } else {
      setIsAuthenticated(false);
      setUsername(null);
      setAuthToken(null); // Remove o token dos headers do axios
    }
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.user);
        setAuthToken(token); // Define o token nos headers do axios
      } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        setUsername(null);
        setAuthToken(null); // Remove o token dos headers do axios
      }
    }
    setCurrentPage("main");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUsername(null);
    setCurrentPage("main");
    setAuthToken(null); // Remove o token dos headers do axios
  };

  return (
    <Router>
      <div className="app-container">
        {isAuthenticated ? (
          <MainContent
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onLogout={handleLogout}
            username={username}
            purchaseMode={purchaseMode}
            updatePurchaseMode={updatePurchaseMode}
            userBalance={userBalance}
            setUserBalance={setUserBalance}
          />
        ) : (
          <AstraShopLogin onLoginSuccess={handleLoginSuccess} />
        )}
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </Router>
  );
};

export default App;
