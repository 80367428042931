// src/axiosConfig.js
import axios from "axios";

// Criação de uma instância do Axios com configurações padrão
const axiosInstance = axios.create({
  baseURL: "https://api.astrashopbot.com/api", // Substitua pelo URL correto da sua API
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Permite o envio de cookies com as requisições
});

// Função para definir o token de autenticação (JWT)
export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// Função para definir o token CSRF
export const setCsrfToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["X-CSRFToken"] = token;
  } else {
    delete axiosInstance.defaults.headers.common["X-CSRFToken"];
  }
};

// Função para fazer logout e redirecionar para a página de login
const handleLogout = () => {
  // Remover o token do localStorage
  localStorage.removeItem("token");
  // Remover o token dos headers do Axios
  setAuthToken(null);
  // Opcional: Exibir uma mensagem informando que a sessão expirou
  console.log("Sessão expirada. Redirecionando para a página de login...");
  // Redirecionar para a página de login
  window.location.href = "/"; // Ajuste a rota conforme necessário
};

// Interceptor para lidar com erros de autenticação
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Autenticação inválida ou expirada
      console.error(
        "Autenticação inválida ou expirada. Por favor, faça login novamente.",
      );
      handleLogout(); // Chama a função de logout
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
