import React, { useState, useEffect } from "react";
import { ShoppingCart, Zap, CreditCard, Shield, Check } from "lucide-react"; // Adicionado Check
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css"; // Assegure-se de importar os estilos do react-toastify

const API_BASE_URL = "https://api.astrashopbot.com";

export default function UserSettings({ updatePurchaseMode, setMenuOpen }) {
  const [purchaseMode, setPurchaseMode] = useState("cart");
  const [checkerEnabled, setCheckerEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatingPurchaseMode, setUpdatingPurchaseMode] = useState(null);
  const [isUpdatingChecker, setIsUpdatingChecker] = useState(false);
  const [checkerOption, setCheckerOption] = useState("debitando");

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user-settings`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const settings = response.data.settings;
      setPurchaseMode(settings.purchaseMode || "cart");
      setCheckerEnabled(!!settings.checkerEnabled);
      setCheckerOption(settings.checkerOption || "debitando");
    } catch (error) {
      console.error("Erro ao buscar as configurações do usuário:", error);
      toast.error("Erro ao buscar as configurações do usuário.");
    } finally {
      setLoading(false);
    }
  };

  const updateUserSettings = async (newSettings) => {
    try {
      await axios.post(
        `${API_BASE_URL}/api/user-settings`,
        { settings: newSettings },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
    } catch (error) {
      throw error;
    }
  };

  const handlePurchaseModeChange = async (value) => {
    setPurchaseMode(value);
    setUpdatingPurchaseMode(value);
    try {
      await updateUserSettings({
        purchaseMode: value,
        checkerEnabled,
        checkerOption,
      });
      // Remover toast de sucesso
      // toast.success("Modo de compra atualizado com sucesso!");
      updatePurchaseMode(value);
    } catch (error) {
      console.error("Erro ao atualizar o modo de compra:", error);
      toast.error("Erro ao atualizar o modo de compra.");
      fetchUserSettings();
    } finally {
      setUpdatingPurchaseMode(null);
    }
  };

  const handleCheckerToggle = async (checked) => {
    setCheckerEnabled(checked);
    setIsUpdatingChecker(true);
    try {
      await updateUserSettings({
        purchaseMode,
        checkerEnabled: checked,
        checkerOption: checked ? checkerOption : undefined,
      });
      // Remover toast de sucesso
      // toast.success("Checker atualizado com sucesso!");
      if (!checked) {
        setCheckerOption("debitando");
      }
    } catch (error) {
      console.error("Erro ao atualizar o checker:", error);
      toast.error("Erro ao atualizar o checker.");
      fetchUserSettings();
    } finally {
      setIsUpdatingChecker(false);
    }
  };

  const handleCheckerOptionChange = async (value) => {
    setCheckerOption(value);
    try {
      await updateUserSettings({
        purchaseMode,
        checkerEnabled,
        checkerOption: value,
      });
      // Remover toast de sucesso
      // toast.success("Opção do checker atualizada com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar a opção do checker:", error);
      toast.error("Erro ao atualizar a opção do checker.");
      fetchUserSettings();
    }
  };

  return (
    <div className="container mx-auto px-4 pt-8">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3} // Limita a 3 toasts simultâneos
      />
      <div className="max-w-2xl mx-auto">
        {loading ? (
          <div className="flex flex-col items-center justify-center h-64 space-y-4">
            <Spinner />
            <div className="text-lg font-semibold">
              Carregando configurações...
            </div>
          </div>
        ) : (
          <div className="bg-card text-card-foreground shadow-sm rounded-lg border p-6">
            <h1 className="text-2xl font-bold mb-6">
              Configurações de Usuário
            </h1>
            <div className="space-y-6">
              <div>
                <h2 className="text-lg font-medium mb-3">Modo de Compra</h2>
                <div className="grid grid-cols-2 gap-4">
                  <PurchaseModeButton
                    icon={<ShoppingCart className="w-8 h-8" />}
                    label="Usar Carrinho"
                    isSelected={purchaseMode === "cart"}
                    onClick={() => handlePurchaseModeChange("cart")}
                    isLoading={updatingPurchaseMode === "cart"}
                  />
                  <PurchaseModeButton
                    icon={<Zap className="w-8 h-8" />}
                    label="Compra Instantânea"
                    isSelected={purchaseMode === "instant"}
                    onClick={() => handlePurchaseModeChange("instant")}
                    isLoading={updatingPurchaseMode === "instant"}
                  />
                </div>
              </div>

              <div className="bg-secondary p-3 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="space-y-0.5">
                    <label
                      htmlFor="checker-toggle"
                      className="text-base cursor-pointer"
                    >
                      Checker
                    </label>
                    <p className="text-sm text-muted-foreground">
                      Verificador automático
                    </p>
                  </div>
                  <ToggleSwitch
                    id="checker-toggle"
                    checked={checkerEnabled}
                    onCheckedChange={handleCheckerToggle}
                    disabled={isUpdatingChecker}
                  />
                </div>
              </div>

              <AnimatePresence>
                {checkerEnabled && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="space-y-3"
                  >
                    <h2 className="text-lg font-medium">Opção do Checker</h2>
                    <div className="grid grid-cols-2 gap-4">
                      <CheckerOptionButton
                        icon={<CreditCard className="w-8 h-8" />}
                        label="Debitando"
                        isSelected={checkerOption === "debitando"}
                        onClick={() => handleCheckerOptionChange("debitando")}
                      />
                      <CheckerOptionButton
                        icon={<Shield className="w-8 h-8" />}
                        label="ZeroAuth"
                        isSelected={checkerOption === "zeroauth"}
                        onClick={() => handleCheckerOptionChange("zeroauth")}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function PurchaseModeButton({ icon, label, isSelected, onClick, isLoading }) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
        isSelected
          ? "bg-blue-600 text-white"
          : "bg-gray-700 text-gray-300 hover:bg-gray-600"
      }`}
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {icon}
          {isSelected && (
            <Check className="absolute top-2 right-2 w-4 h-4 text-white" /> // Ícone de Check
          )}
        </>
      )}
      <span className="mt-2 text-sm text-center">{label}</span>
    </motion.button>
  );
}

function CheckerOptionButton({ icon, label, isSelected, onClick }) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
        isSelected
          ? "bg-green-600 text-white"
          : "bg-gray-700 text-gray-300 hover:bg-gray-600"
      }`}
    >
      {isSelected && (
        <Check className="absolute top-2 right-2 w-4 h-4 text-white" /> // Ícone de Check
      )}
      {icon}
      <span className="mt-2 text-sm text-center">{label}</span>
    </motion.button>
  );
}

function ToggleSwitch({ checked, onCheckedChange, disabled }) {
  return (
    <motion.button
      className={`w-12 h-7 rounded-full flex items-center ${
        checked ? "bg-green-600 justify-end" : "bg-gray-700 justify-start"
      }`}
      onClick={() => !disabled && onCheckedChange(!checked)}
      whileTap={{ scale: 0.9 }}
      disabled={disabled}
      style={{ padding: "2px" }}
    >
      <motion.div
        className="bg-white w-6 h-6 rounded-full shadow-md"
        layout
        transition={spring}
      />
    </motion.button>
  );
}

function Spinner() {
  return (
    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-current"></div>
  );
}

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
